*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins',sans-serif;
}


.bg{
    background-image: url("bg.jpg");
    width: 1370px;
    height: 100vh;
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: center;
}
.header
{
position: fixed;
top: 0;
left: 0;
width: 100%;
padding: 20px 100px;
background: rgba(255,255,255,.1);
display: flex;
justify-content: space-between;
align-items: center;
backdrop-filter: blur(10px);
border-bottom: 2px solid rgba(255,255,255,.2);
}
.header::before{
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,transparent,rgba(255,255,255, .4),transparent);
    transition: .5s;
}
.header:hover::before{
    left: 100%;
}
.logo
{
    color: #fff;
    font-size: 25px;
    text-decoration: none;
    font-weight: 600;
    cursor: default;
}
.navbar a{
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    margin-left: 35px;
    transition: .3s;
}
.header a:hover
{
color: #f00;
}
#menu-icon
{
    font-size: 36px;
    color: #fff;
    display: none;
}
@media (max-width:992px){
    .header{
        padding:1.25rem 4%;
    }
}
@media (max-width:768px){
    #menu-icon {
        display: block;
}
.navbar{
    position: fixed;
    top:100%;
    left: 0;
    width: 100%;
    padding: .5rem 4%;
    display: none;
}

.navbar.active{
    display: block;
}
.navbar a {
display: block;
margin: 1.5rem 0;
}
}
/* .nav-bg{
    position: fixed;
    top: 11.8%;
    left: 0;s
    width: 100%;
    height: 295px;
    background: rgba(255,255,255,.1);
    border-bottom: 2px solid rgba(255,255,255,.2);
    backdrop-filter: blur(10px);
    z-index: 99;
    display: none;
}
.nav-bg.active{
    display: block;
} */

